@import 'antd/es/style/core/index.less';
@import 'antd/es/button/style/index.less';
@import 'antd/es/input/style/index.less';
@import 'antd/es/grid/style/index.less';
@import 'antd/es/progress/style/index.less';
@import 'antd/es/typography/style/index.less';
@import 'antd/es/form/style/index.less';
@import 'antd/es/checkbox/style/index.less';
@import 'antd/es/alert/style/index.less';
@import 'antd/es/steps/style/index.less';
@import 'antd/es/upload/style/index.less';
@import 'antd/es/select/style/index.less';
@import 'antd/es/tabs/style/index.less';
@import 'antd/es/time-picker/style/index.less';
@import 'antd/es/date-picker/style/index.less';
@import 'antd/es/menu/style/index.less';
@import 'antd/es/layout/style/index.less';
@import 'antd/es/switch/style/index.less';
@import 'antd/es/tag/style/index.less';
@import 'antd/es/table/style/index.less';
@import 'antd/es/pagination/style/index.less';
@import 'antd/es/dropdown/style/index.less';
@import 'antd/es/modal/style/index.less';
@import 'antd/es/spin/style/index.less';
@import 'antd/es/tooltip/style/index.less';
@import 'antd/es/avatar/style/index.less';
@import 'antd/es/radio/style/index.less';
@import 'antd/es/popover/style/index.less';
@import 'antd/es/notification/style/index.less';

@border-radius-base: 20px;
@basic-height: 40px;
@border-radius-base: 20px;
@border-radius-sm: 4px;
@border-radius-small: 4px;
@btn-danger-bg: #ff4141;
@btn-danger-border: #ff4141;
@btn-danger-color: #ffffff;
@btn-default-bg: #ffffff;
@btn-default-border: #9e9e9e;
@btn-default-color: #606060;
@btn-default-ghost-bg: transparent;
@btn-default-ghost-border: #606060;
@btn-default-ghost-color: #606060;
@btn-font-size-base: 14px;
@btn-font-size-sm: 14px;
@btn-font-weight: 400;
@btn-height-base: 40px;
@btn-height-sm: 32px;
@btn-line-height-base: 1.7;
@btn-line-height-sm: 1.25;
@btn-padding-horizontal-base: 24px;
@btn-padding-horizontal-small: 8px;
@btn-success-bg: #39b473;
@btn-success-border: #39b473;
@btn-success-color: #ffffff;
@default-bg: #f0f0f0;
@error-color: #ff4141;
@error-color-active: #ff6161;
@error-color-deprecated-border: #ff6161;
@error-color-deprecated-dg: #ff6161;
@error-color-hover: #ff6161;
@error-color-outline: #ff6161;
@font-family: 'Poppins', sans-serif;
@font-size-base: 14px;
@font-size-large: 24px;
@font-size-small: 12px;
@heading-1-size: 40px;
@heading-2-size: 32px;
@heading-3-size: 24px;
@heading-4-size: 16px;
@heading-5-size: 14px;
@heading-6-size: 12px;
@info-color: #0078f9;
@info-color-active: #3295ff;
@info-color-deprecated-border: #3295ff;
@info-color-deprecated-dg: #3295ff;
@info-color-hover: #3295ff;
@info-color-outline: #3295ff;
@input-disabled-color: #6060604d;
@input-height-base: 40px;
@input-hover-border-color: #9a49ff;
@input-placeholder-color: #9e9e9e;
@link-color: #9a49ff;
@link-decoration: none;
@link-hover-color: #6f1ada;
@link-hover-decoration: underline;
@primary-color: #602dd3;
@primary-color-active: #9a49ff;
@primary-color-deprecated-border: #9a49ff;
@primary-color-deprecated-dg: #9a49ff;
@primary-color-hover: #9a49ff;
@primary-color-outline: #9a49ff;
@progress-default-color: #9e9e9e;
@success-color: #39b473;
@success-color-active: #2ecb77;
@success-color-deprecated-border: #2ecb77;
@success-color-deprecated-dg: #2ecb77;
@success-color-hover: #2ecb77;
@success-color-outline: #2ecb77;
@text-color-primary: #1c2732;
@text-color-secondary: #121920;
@warning-color: #f6ae2d;
@warning-color-active: #ecbe6c;
@warning-color-deprecated-border: #ecbe6c;
@warning-color-deprecated-dg: #ecbe6c;
@warning-color-hover: #ecbe6c;
@warning-color-outline: #ecbe6c;

/*breakpoints*/
@screen-xs: 480px;
@screen-xs-min: @screen-xs;
@screen-sm: 576px;
@screen-sm-min: @screen-sm;
@screen-md: 768px;
@screen-md-min: @screen-md;
@screen-lg: 992px;
@screen-lg-min: @screen-lg;
@screen-xl: 1200px;
@screen-xl-min: @screen-xl;
@screen-xxl: 1600px;
@screen-xxl-min: @screen-xxl;
@screen-xs-max: (@screen-sm-min - 1px);
@screen-sm-max: (@screen-md-min - 1px);
@screen-md-max: (@screen-lg-min - 1px);
@screen-lg-max: (@screen-xl-min - 1px);
@screen-xl-max: (@screen-xxl-min - 1px);

//Alerts
@alert-success-border-color: fade(@success-color, 10%);
@alert-success-bg-color: fade(@success-color, 10%);
@alert-success-icon-color: @success-color;
@alert-info-border-color: fade(@info-color, 10%);
@alert-info-bg-color: fade(@info-color, 10%);
@alert-info-icon-color: @info-color;
@alert-warning-border-color: fade(@warning-color, 10%);
@alert-warning-bg-color: fade(@warning-color, 10%);
@alert-warning-icon-color: @warning-color;
@alert-error-border-color: fade(@error-color, 10%);
@alert-error-bg-color: fade(@error-color, 10%);
@alert-error-icon-color: @error-color;
// Swithch
@switch-height: 20px;
@switch-sm-height: 15px;
@switch-min-width: 40px;
@switch-sm-min-width: 28px;
/*magic*/
iframe {
  pointer-events: none;
}

// Table
@table-body-sort-bg: transparent;
@table-selected-row-bg: transparent;
//Modal
@modal-header-border-width: 0;
@modal-footer-border-width: 0;

//Avatar
@avatar-font-size-base: 16px;

// Radio
@radio-size: 20px;
@radio-top: 0.2em;
@radio-border-width: 2px;
@radio-dot-size: @radio-size - 8px;
@radio-dot-color: #0078F9;
@radio-dot-disabled-color: fade(@black, 20%);
@radio-solid-checked-color: @component-background;

